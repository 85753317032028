<template>
  <b-card no-body class="p-2">
    <b-row>
      <b-col xs="12">
        <h2>Developer API Key</h2>
        <p class="mt-1">
          Generate an API key below and start using our developer API to send SMS's from your 3rd party business systems
        </p>
        <hr />
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="generateApiKey"
            :disabled="isLoading"
          >
            <b-spinner small v-if="isLoading" />
            <span v-if="isLoading">&nbsp;</span>
            Generate API Key
          </b-button>
          <transition name="fade" mode="out-in">
            <b-form-group label="API Key (store the API Key as it will only be visible once)" class="mt-2" v-if="apiKey">
              <div class="form-control api-key pr-5 position-relative bg-light">
                {{apiKey}}
                <b-button
                  variant="gradient-primary"
                  class="btn-icon position-absolute copy-btn"
                  size="sm"
                  @click="copyApiToClipboard"
                >
                  <feather-icon icon="CopyIcon" />
                </b-button>
              </div>
            </b-form-group>
          </transition>

      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col md="6">
        <blockquote class="blockquote endpoint-blockquote pl-1">
          <h3>Endpoint</h3>
          <p class="ml-1">{{appUrl}}/v1/api/developers/send-sms</p>
        </blockquote>
        <h4 class="mt-3">Request Header</h4>
        <ul>
          <li>
            <strong>Content-Type:</strong> should be set to <code>application/json</code> as the api only accept requests which has a body of JSON
          </li>
        </ul>

        <h4 class="mt-2">Request Body</h4>
        <ul>
          <li>
            <strong>sender_id:</strong> Your registered {{appName}} Sender ID
          </li>
          <li>
            <strong>country_code:</strong> Specify the country code of phone numbers that pepeasms platform supports.
          </li>
          <li>
            <strong>phone_numbers:</strong> List of phone numbers to send message to.
          </li>
          <li>
            <strong>message:</strong> The message you wish to send to your recipient(s).
          </li>
          <li>
            <strong>api_key:</strong> Your api key key generated above.
          </li>
        </ul>
      </b-col>
      
      <b-col md="6">
        <pre data-v-96de0c86="" class="rounded mb-0 language-javascript"><code data-v-96de0c86="" class="language-javascript">curl <span class="token operator">-</span>X <span class="token constant">POST</span> \<br>   <span class="token string">{{appUrl}}/v1/api/developers/send-sms</span> \<br>    <span class="token operator">-</span>H <span class="token string">'Content-Type: application/json'</span> \<br>    <span class="token operator">-</span>d <span class="token string">'{ <br>       "sender_id": "PEPEASMS", <br>       "country_code": "254", <br>       "phone_numbers": [ <br>         "7XXXXXXXX" <br>       ], <br>       "message": "an example message", <br>       "api_key": "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX" <br>    }'</span> </code></pre>
      </b-col>

    </b-row>

    <b-row class="mt-3">
      <b-col md="6">
      <h3>Successful Response Example</h3>
      <pre data-v-96de0c86="" class="rounded mb-0 language-javascript"><code data-v-96de0c86="" class="language-javascript">{ <br>    "success": true,<br>    "status_code": 200,<br>    "message": "Your request to send sms has been recieved.<br>     The credits will be deducted automatically from you at the time<br>     when the sms actually gets sent.", <br> }</code></pre>
      </b-col>

      <b-col md="6">
        <h3>Error Response Example</h3>
        <pre data-v-96de0c86="" class="rounded mb-0 language-javascript"><code data-v-96de0c86="" class="language-javascript">{ <br>    "success": false,<br>    "status_code": 400,<br>    "error":  [ <br>      "Invalid User. Generate a new api key and try again.", <br>    ], <br> }</code></pre>
      </b-col>
    </b-row>


  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BSpinner,
  BCol,
  BRow,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from '@themeConfig';
import 'prismjs/themes/prism-tomorrow.css'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BSpinner,
    BCol,
    BRow,
  },
  data(){
    const { appName, appUrl } = $themeConfig.app;
    return {
      isLoading: false,
      apiKey: null,
      appUrl,
      appName,
    };
  },
  methods: {
    generateApiKey() {
      this.isLoading = true;
      this.$http
        .get("/developers/generate-api-key")
        .then((response) => {
          this.apiKey =  response.data.data;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "API Key Generated Successfully",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          this.isLoading = false;
        });
    },
    copyApiToClipboard(){
      navigator.clipboard.writeText(this.apiKey).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "API Key Copied to Clipboard",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      }, (err) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: err,
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });
    }
  },
};
</script>

<style scoped>
.api-key {
  height: auto !important;
}
.copy-btn{
  right: 0; 
  top: 0; 
  bottom: 0; 
  margin-top: auto; 
  margin-bottom: auto; 
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease-in-out;
}
.endpoint-blockquote{
  border-left: 6px solid #0052ff;
  border-radius: 5px;
}
</style>